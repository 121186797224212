<template>
  <div class="fieldset">
    <h3 class="fieldset-heading">Name</h3>

    <Title
        v-model="form.profileNameTitle"
        save-location="coupon_client"
    />
    <FullName :value-first="form.profileNameFirst"
              :value-middle="form.profileNameMiddle"
              :value-last="form.profileNameLast"
              @inputFirst="form.profileNameFirst=$event"
              @inputMiddle="form.profileNameMiddle=$event"
              @inputLast="form.profileNameLast=$event"
              save-location="coupon_client"

    />
  </div>
</template>

<script>
import {fieldsetHelpers} from "../../fieldsetHelpers";
import { statusOptions } from "../../../question/options/statusOptions";

import Title from "../../../question/questions/clientProfile/name/Title";
import FullName from "../../../question/questions/clientProfile/name/FullName";


export default {
  name: 'NameBasic',
  mixins: [fieldsetHelpers, statusOptions],
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  components: {
    FullName,
    Title
  },
  data() {
    return {
      label: 'Name' // used for steps progress
    }
  },
  computed: {
    form: {
      set(value) {
        this.$emit('input', value)
      },
      get() {
        return this.value
      }
    }
  }
};
</script>
