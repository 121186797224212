<template>
  <div class="main-content-wrap d-flex flex-column">
    <QuestionnaireBase
        :title="title"
        :form="form"
        :finishFunction="null"
        :showNavigationButtons="true"
        :customFinishRoutine="register"
        :finishButtonText="finishButtonText"
    >
      <template v-slot:default>
        <component :is="show" :key="1" :ref="show" v-model="form"
                   @finishButtonText="finishButtonText=$event"/>

        <component
            v-if="load in $options.components"
            v-show="false"
            :is="load"
            key="load"
            :ref="load"
            v-model="form"
        />

      </template>
    </QuestionnaireBase>
  </div>
</template>

<script>
import {loadHelpers} from "@/views/questionnaires/loadHelpers";

import QuestionnaireBase from "../../../components/common/questionnaires/questionnaire";
import NameBasic from "@/components/common/questionnaires/fieldsets/coupon/estate_planning/NameBasic";
import GenderDOB from "@/components/common/questionnaires/fieldsets/coupon/estate_planning/GenderDOB";
import Contact from "@/components/common/questionnaires/fieldsets/coupon/estate_planning/Contact";
import Register from "@/components/common/questionnaires/fieldsets/register/CouponClientRegister";
import Address from "@/components/common/questionnaires/fieldsets/estatePlanning/simple/Address";
import Relationship from "@/components/common/questionnaires/fieldsets/estatePlanning/simple/Relationship";

export default {
  name: "Coupon",
  mixins: [loadHelpers],
  components: {
    QuestionnaireBase,
    NameBasic,
    GenderDOB,
    Address,
    Register,
    Relationship,
    Contact
  },
  data() {
    return {
      name: 'Coupon',
      title: "MyLastWill - Profile",
      url: 'coupon_start',
      order: [ // imported components required here to load
        'NameBasic',
        'GenderDOB',
        'Address',
        'Relationship',
        'Contact',
        'Register',
      ],
      expiryAccessCodeOnFinish: false,
      storeExtraGetData: this.storeCoupon,
      finishButtonText: 'Create Account'
    };
  },
  computed: {
    refs() {
      return this.$refs
    }
  },
  methods: {
    storeCoupon(data) {
      this.$store.commit('coupon', data.coupon)
    },
    register() {
      // override finish routine with register functions from Register component
      if (this.show === 'Register') {
        return this.$refs.Register.create()
      }
    }
  }
};
</script>
