<template>
  <div class="fieldset">
    <h3 class="fieldset-heading">Contact Information</h3>
    <MobileNumber
        v-model="form.profileMobileNumber"
        save-location="coupon_client"
    />
    <Email
        v-model="form.profileEmail"
        save-location="coupon_client"
    />
    <MarketingConsent
        v-model="form.profileMarketingConsent"
        :consent-date="form.profileMarketingConsentDate"
        save-location="coupon_client"
    />
  </div>
</template>

<script>
import { fieldsetHelpers} from "@/components/common/questionnaires/fieldsets/fieldsetHelpers";

import MobileNumber from "@/components/common/questionnaires/question/questions/clientProfile/contact/MobileNumber";
import Email from "@/components/common/questionnaires/question/questions/clientProfile/contact/Email";
import MarketingConsent from "@/components/common/questionnaires/question/questions/clientProfile/contact/Marketing";

export default {
  name: 'Contact',
  mixins: [fieldsetHelpers],
  props: {
    value: {
      type: Object,
      required: true
    },
    saveLocation: {
      type: String,
      default: 'coupon_client'
    }
  },
  components: {
    MarketingConsent,
    Email,
    MobileNumber
  },
  data () {
    return {
      label: 'Contact Details' // used for steps progress
    }
  },
  computed: {
    form: {
      set(value) {
        this.$emit('input', value)
      },
      get () {
        return this.value
      }
    }
  }
};
</script>
